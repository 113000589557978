import { useMap } from "../useMap";
import { TranslationContext } from "./TranslationContext";

const { Provider } = TranslationContext;

export const TranslationProvider = ({ children }) => {
  const [translationsMap, addToTranslationsMap, clearTranslationsMap] = useMap();

  return (
    <Provider value={{ translationsMap, addToTranslationsMap, clearTranslationsMap }}>
      {children}
    </Provider>
  )
}
